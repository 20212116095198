import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import FormBlock from "../../../components/form-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const ContactPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			imageBg: file(relativePath: { eq: "backgrounds/chick-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Contact"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={siteColour.hex}
					link={`/`}/>
				<Split>
					<ImageBlock 
						image={ data.imageBg.childImageSharp.fluid }
						position={`20% 60%`}/>
					<FormBlock 
						thanks={'/painesend/contact/thanks'}
						bgColour={siteColour.slug}/>					
				</Split>
			</Layout>
		</>
	)
}

export default ContactPage